<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <!-- <fb-button slot="right"
                 icon="menu"
                 size="small"
                 type="primary">
      </fb-button> -->
    </fb-header>
    <div class="oa-flow">
      {{title}}
    </div>
  </fb-page>
</template>

<script>
export default {
  name: 'oa-flow',
  components: {},
  data() {
    return {
      title: '流程管理'
    }
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>

<style lang='stylus'>
.oa-flow
  position relative
</style>
